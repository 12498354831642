import React, { useState, useEffect, useCallback } from 'react';
import Moment from 'moment';
import { Loading } from '../Loading';
import { hasAlert, LeiturasService, sseNewObservable } from '../../service/LeiturasService';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { DialogLeitura } from './DialogLeitura'
import { TabelaLeituras } from "./TabelaLeituras";
import { Button } from 'primereact/button';
import { getNome } from '../../service/AuthService'

const leiturasService = new LeiturasService();

export function MaquinaResumo(props) {
  const { match: { params } } = props;
  
  const [firstLoad, setFirstLoad] = useState(true);
  const [dados, setDados] = useState(null);
  const [leituraSelecionada, setLeituraSelecionada] = useState(null);
  const [dataInicial, setDataInicial] = useState(params.ini ? new Date(Number(params.ini)) : Moment(new Date()).subtract(1, 'days').toDate());
  const [dataFinal, setDataFinal] = useState(params.fim ? new Date(Number(params.fim)) : Moment(new Date()).toDate());
  

  const maquina = dados ? dados.maquina : { descricao: "" };
  const leituras = dados ? dados.dados : null;

  const changeDataInicial = useCallback(e => {
    setDataInicial(e.value);
  }, [setDataInicial]);

  const changeDataFinal = useCallback(e => {
    setDataFinal(e.value);
  }, [setDataFinal]);

  const fecharDialogLeitura = useCallback(() => {
    setLeituraSelecionada(null);
  }, [setLeituraSelecionada]);

  const onSelectLeitura = useCallback(e => {
    setLeituraSelecionada(e.value);
  }, [setLeituraSelecionada]);

  const buscarInformacoes = useCallback(e => {
    setDados(null);
    leiturasService.getResumoPeriodo(params.id, dataInicial, dataFinal)
      .then(data => {
        if (!data) {
            return {};
        }
        data.dados = data.dados.map(d => {
          return {
            ...d,
            fieldHoraLeitura: montarColunaHora(d.horaLeitura),
            fieldTemp1: montarColuaValorPadrao(d.temperatura1 + "/" + d.setpointTemperatura),
            fieldTemp2: montarColuaValorPadrao(d.temperatura2 + "/" + d.setpointTemperatura),
            fieldMedia: montarColuaValorPadrao(d.temperaturaMedia + "/" + d.setpointTemperatura),
            fieldExt: montarColuaValorPadrao(d.temperaturaExterna),
            fieldUmidadeA: montarColuaValorPadrao(d.umidade1 + "/" + d.setpointUmidade),
            fieldUmidadeB: montarColuaValorPadrao(d.umidade2 + "/" + d.setpointUmidade),
            fieldUmidadeMedia: montarColuaValorPadrao(d.umidadeMedia + "/" + d.setpointUmidade),
            fieldCo2A: montarColuaValorPadrao(d.co2A + "/" + d.setpointCo2),
            fieldCo2B: montarColuaValorPadrao(d.co2B + "/" + d.setpointCo2),
            fieldCo2Media: montarColuaValorPadrao(d.co2Media + "/" + d.setpointCo2),
            fieldIgnicao: montarColuaValorBooleanos(d.ignicao),
            fieldPorta: montarColuaValorBooleanos(!d.alarmePortaAberta),
            fieldAlternador: montarColuaValorBooleanos(!d.alarmeAlternador),
            fieldAutomatico: montarColuaValorManual(d.modoManual),
            hasAlerts: hasAlert(d),
          }
        });
        data.horaConsulta = new Date();
        setDados(data);
      });
  }, [dataInicial, dataFinal, params]);

  useEffect(() => {
    if (firstLoad) {
        buscarInformacoes();
        setFirstLoad(false);
        /*sseNewObservable.subscribe({
            next(l) {
                // const leitura = JSON.parse(l.data);
                // console.log(leitura);
                // var data = leitura.horaLeitura;
                // if (dataFinal > data) {
                //     console.log(dataFinal);
                // }
                const leitura = JSON.parse(l.data);
                addNovasLeituras(leitura);
            }
        });*/
    }
  }, [buscarInformacoes, dados, /*addNovasLeituras*/]);

  /*const addNovasLeituras = useCallback(e => {
    const leitura = JSON.parse(l.data);
    console.log(leitura);
    var data = leitura.horaLeitura;
    if (dataFinal > data) {
        console.log(dataFinal);
    }
  }, [dataInicial, dataFinal]);*/

  if (!leituras) {
    return <Loading/>
  }

  const isExportLocation = (window.location + '').includes('/export');
  const pageStyle = isExportLocation ? { margin: '0px 10px', width: '210mm' } : {};

  function redirectImprimir() {
    let loc = window.location + '';
      if (!loc.endsWith('/')) {
        loc += '/';
      }
      window.location = `${loc}export/${Number(dataInicial)}/${Number(dataFinal)}`;
  }
  
  return (
    <div className={!isExportLocation ? "p-grid" : undefined} style={pageStyle}>
      <div className="p-col-12 p-md-12">
        <Card>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="p-card-title">
                    {maquina.descricao}
                </div>
                <div>
                    {isExportLocation ? getNomeUsuario() : ""}
                </div>
            </div>
          <div className="p-grid">
            <div className="p-col" style={{ maxWidth: '230px', minWidth: '230px' }}>
              <div><label>Data Inicial</label></div>
              <Calendar 
                value={dataInicial} 
                onChange={changeDataInicial} 
                showIcon={!isExportLocation}
                dateFormat="dd/mm/yy"
                disabled={isExportLocation}
                showTime={true}>
              </Calendar>
            </div>
            <div className="p-col" style={{ maxWidth: '230px', minWidth: '230px' }}>
              <div><label>Data Final</label></div>
              <Calendar 
                value={dataFinal} 
                onChange={changeDataFinal} 
                showIcon={!isExportLocation}
                dateFormat="dd/mm/yy"
                disabled={isExportLocation}
                showTime={true}>
              </Calendar>
            </div>
            <div className="p-col" style={{ minWidth: '230px' }}>
              <br/>
              {!isExportLocation && <Button label="Filtrar" icon="pi pi-search" onClick={buscarInformacoes}/>}
              {!isExportLocation && <Button label="Imprimir" icon="pi pi-print" onClick={redirectImprimir} style={{marginLeft: '10px'}}/>}
            </div>
          </div>
        </Card>
      </div>
      <div className="p-col-12 p-md-12">
        <Card title="Temperatura">
          <GraficoLinha data={mountGraficoTemperaturaData(leituras)} horaConsulta={dados.horaConsulta}/>
        </Card>    
      </div>
      <div className='p-col-12'>
        <Card title="Umidade">            
          <GraficoLinha data={mountGraficoUmidadeData(leituras)} horaConsulta={dados.horaConsulta}/>
        </Card>    
      </div>
      <div className='p-col-12'>
        <Card title="Co2">            
          <GraficoLinha data={mountGraficoCo2Data(leituras)} horaConsulta={dados.horaConsulta}/>
        </Card>    
      </div>
      <div className="p-col-12" style={{ pageBreakBefore: 'always' }}>
        <TabelaLeituras leituras={leituras} onSelect={onSelectLeitura}/>
      </div>
      <DialogLeitura leitura={leituraSelecionada} onHide={fecharDialogLeitura}/>
    </div>
  );
}

function montarColuaValorPadrao(texto) {
  return <div style={{textAlign: 'center'}}> {texto} </div>;
}

function montarColuaValorBooleanos(valor) {
  let ret = valor ? <i className="pi pi-check" style={{color: 'green'}}/> : <i className="pi pi-exclamation-triangle" style={{color: 'red'}}></i>;
  return montarColuaValorPadrao(ret);
}

function montarColuaValorManual(valor) {
  let ret = valor ? 'MAN' : 'AUT';
  return montarColuaValorPadrao(ret);
}

function montarColunaHora(valor) {
  let tempo = new Date(valor);    
  return Moment(tempo).format('DD/MM/YY HH:mm');
}

function mountGraficoTemperaturaData(leituras) {
  let obj = {
    labels: leituras.map(l => Moment(new Date(l.horaLeitura)).format('DD/MM HH:mm')),      
    datasets: [
      {
        label: 'Temperatura 1',
        data: leituras.map(l => l.temperatura1),
        fill: false,
        backgroundColor: '#42A5F5',
        borderColor: '#42A5F5'
      },
      {
        label: 'Temperatura 2',
        data: leituras.map(l => l.temperatura2),
        fill: false,
        backgroundColor: '#66BB6A',
        borderColor: '#66BB6A'
      },
      {
        label: 'Externa',
        data: leituras.map(l => l.temperaturaExterna),
        fill: false,
        backgroundColor: '#edb343',
        borderColor: '#edb343'
      },
      {
        label: 'Média',
        data: leituras.map(l => l.temperaturaMedia),
        fill: false,
        backgroundColor: '#e33c3c',
        borderColor: '#e33c3c'
      },
      {
        label: 'Setpoint',
        data: leituras.map(l => l.setpointTemperatura),
        fill: false,
        backgroundColor: '#b73ce3',
        borderColor: '#b73ce3'
      }
    ]
  }
  return obj;
}

function mountGraficoUmidadeData(leituras) {
    let datasets;
    
    if (leituras[0] && leituras[0].versao === 1) {
        datasets = [
            {
                label: 'Umidade',
                data: leituras.map(l => l.umidadeMedia),
                fill: false,
                backgroundColor: '#42A5F5',
                borderColor: '#42A5F5'
            },
            {
                label: 'Setpoint',
                data: leituras.map(l => l.setpointUmidade),
                fill: false,
                backgroundColor: '#b73ce3',
                borderColor: '#b73ce3'
            }
        ]
    } else {
        datasets = [
            {
                label: 'Umidade 1',
                data: leituras.map(l => l.umidade1),
                fill: false,
                backgroundColor: '#42A5F5',
                borderColor: '#42A5F5'
            },
            {
                label: 'Umidade 2',
                data: leituras.map(l => l.umidade2),
                fill: false,
                backgroundColor: '#66BB6A',
                borderColor: '#66BB6A'
            },
            {
                label: 'Umidade Média',
                data: leituras.map(l => l.umidadeMedia),
                fill: false,
                backgroundColor: '#e33c3c',
                borderColor: '#e33c3c'
            },
            {
                label: 'Setpoint',
                data: leituras.map(l => l.setpointUmidade),
                fill: false,
                backgroundColor: '#b73ce3',
                borderColor: '#b73ce3'
            }
        ]
    }

    let obj = {
        labels: leituras.map(l => Moment(new Date(l.horaLeitura)).format('DD/MM HH:mm')),
        datasets: datasets,
    }
    
    return obj;
}

function mountGraficoCo2Data(leituras) {
    let datasets;

    if (leituras[0] && leituras[0].versao === 1) {
        datasets = [
            {
                label: 'Co2',
                data: leituras.map(l => l.co2Media),
                fill: false,
                backgroundColor: '#42A5F5',
                borderColor: '#42A5F5'
            },
            {
                label: 'Setpoint',
                data: leituras.map(l => l.setpointCo2),
                fill: false,
                backgroundColor: '#b73ce3',
                borderColor: '#b73ce3'
            }
        ];
    } else {
        datasets = [
            {
                label: 'Co2 A',
                data: leituras.map(l => l.co2A),
                fill: false,
                backgroundColor: '#42A5F5',
                borderColor: '#42A5F5'
            },
            {
                label: 'Co2 B',
                data: leituras.map(l => l.co2B),
                fill: false,
                backgroundColor: '#66BB6A',
                borderColor: '#66BB6A'
            },
            {
                label: 'Co2 Médio',
                data: leituras.map(l => l.co2Media),
                fill: false,
                backgroundColor: '#e33c3c',
                borderColor: '#e33c3c'
            },
            {
                label: 'Setpoint',
                data: leituras.map(l => l.setpointCo2),
                fill: false,
                backgroundColor: '#b73ce3',
                borderColor: '#b73ce3'
            }
        ]
    }

    let obj = {
        labels: leituras.map(l => Moment(new Date(l.horaLeitura)).format('DD/MM HH:mm')),
        datasets: datasets
    }
    
    return obj;
}

function getNomeUsuario() {
    if (!window.username) {
        window.username = getNome();
    }
    return window.username;
}

const GraficoLinha = React.memo(({ data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: true            
        }
      }]
    },
    animation: null
  }

  const isExportLocation = (window.location + '').includes('/export');

  return (
    <Chart 
      style={{ height: (isExportLocation ? '240px' : '250px') }} 
      type="line" 
      data={data} 
      options={options}
    />
  )
}, (prev, next) => {
  return next.horaConsulta === prev.horaConsulta;
});